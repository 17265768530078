import React, { useState } from 'react';
import { IonCardTitle, IonCardHeader, IonCard, IonBadge, IonText, IonContent, IonPage, IonInput, IonItem, IonLabel, IonList, IonButton ,IonItemDivider, IonSelect, IonSelectOption,useIonAlert ,IonIcon, IonTitle, IonHeader} from '@ionic/react';
import HeaderContainer from '../components/HeaderContainer';
import { useForm } from "react-hook-form";
import { RouteComponentProps } from 'react-router';

import { camera, helpCircle, card } from 'ionicons/icons';
         
import { usePhotoGallery} from '../hooks/usePhotoGallery';
import { Interface } from 'readline';



export interface Client {
  id: number | undefined,
  name: string,
  mobile: string,
  email: string,
  address: string,
  image: string,
  existingCustomer: boolean,
  suburb_id: number | undefined,
  suburb: Suburb | undefined,
  card: string | undefined,
  payment_added: boolean,
  profile_checked: boolean
}

export interface Suburb {
  id: number,
  name: string,
  del_day: string
}

interface ClientErrors {
  name: any,
  mobile: any,
  email: any,
  address: any,
  image: any,
  existingCustomer: any,
  suburb_id: any
}

interface ClientPageProps extends RouteComponentProps { 
  saveClient: any,
  uploadPhoto: any,
  onLogout: any,
  client: Client | undefined ,
  suburbs: Suburb[] | undefined 
}

interface FormErrorProps { 
  errors: Record<string, string>
}

const FormError: React.FC<FormErrorProps> = ( props) => {
  const  errors = props.errors;

  const  items = Object.entries(errors).map(([key, value])  =>  {
      return  (
        <span key={key}> {value}</span>
      );
  });

  return (<>
      {items}
  </>);
}

const ClientPage: React.FC<ClientPageProps> = ({ history, match, saveClient, client, suburbs, onLogout, uploadPhoto }) => {
  const [present] = useIonAlert();
  const { photo, takePhoto } = usePhotoGallery();

  const defaultValues:Client = client || {
    name: "", 
    mobile:"", 
    email:"", 
    address:"",
    image:"", 
    id:undefined, 
    suburb: undefined,
    card: undefined,
    suburb_id: undefined, 
    existingCustomer: false,
    profile_checked: false,
    payment_added: false
  };
  
  const [formErrors, setFormErrors] = useState<ClientErrors>();

  const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});

  const onSubmit = async (data: Client) => {
    //SaveClient
    
    const responce = await saveClient(data);

    console.log(responce)
    if(responce?.status == 200){
      //history.goBack(); - this does something strange on first use
    }else{
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      present($message, [{ text: 'Ok' }]);
      setFormErrors(responce.errors);
    }
  };

  const onTakePhoto = async () => {

    const newPhoto = await takePhoto();

    if(newPhoto){
      const responce = await uploadPhoto(newPhoto);

      console.log(responce)
      if(responce?.status == 200){
        //history.goBack();
        present('Success', [{ text: 'Ok' }]);
      }else{
        let $message = responce.message ? responce.message : 'Error uploading photo, please try again.';
        present($message, [{ text: 'Ok' }]);
      }
    }

  };

  const logout = async () => {

    const responce = await onLogout();

    if(responce){
      history.push('/')
    }else{
      let $message = responce?.message ? responce.message : 'Issue logging out, please try again.';
      present($message, [{ text: 'Ok' }]);
    }
  };

  interface InputProps {
    inputText: any,
    error: any,
    register: any,
    type: string,
    specForm: any,
    placeholder: string | undefined,
    list: any | undefined,
    itemType: Interface | undefined
  }

  function fieldRequired() {
    return <IonBadge color="danger"><IonLabel>Required</IonLabel></IonBadge>
  }

  const CustomInput = ({inputText, error, register, specForm, type, placeholder, list, itemType}: InputProps) => {
    const fieldRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      if (error && fieldRef.current){fieldRef.current.scrollIntoView({behavior: "smooth",});}
    }, [error]);

    const inputSelector = () => {
      if (type === "text"){
        return <IonInput position="floating" color='dark' type="text" defaultValue="" {...register}></IonInput>
      } else if (type === "tel"){
        return <IonInput defaultValue="" type="tel" {...register}></IonInput>
      } else if (type === "email"){
        return <IonInput defaultValue="" type="email" {...register}></IonInput>
      } else if (type === "select"){
        <IonSelect 
              defaultValue="" 
              placeholder={placeholder}
              {...register} 
              interface="popover"
            >
            {/* {list && list.map((item:typeof itemType, index:number) => (
              <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
            ))} */}
            </IonSelect>

      }

    }
    
    return (
      <div ref={fieldRef}>
        <IonItem color='light'>
              <IonLabel color="dark">{inputText}</IonLabel>
                {inputSelector()}
                {error && fieldRequired()}
                {formErrors && specForm && <FormError errors={specForm}/>}
        </IonItem>
      </div>
    );
  };

  

  /*
  If account is active on server
  Set as Update
  Otherwise: Register

https://stripe.com/docs/payments/payment-methods

  */

  return (
    <IonPage>
      <HeaderContainer here={match.url} title="Profile" showBack={true} />
      <IonContent>
      <IonHeader style={{backgroundColor: '#1450A0', height: '10vh'}} className="ion-padding-top ion-padding-bottom">
        
        <IonTitle className="ion-padding-top ion-padding-bottom" style={{fontWeight: 'normal',fontSize: '25px', color: 'white'}}>Profile Settings</IonTitle> 
      
      
    </IonHeader>
      
      <form onSubmit={handleSubmit(onSubmit)}>
      <IonCard className="profile-card">
      <IonCardHeader>
            <IonCardTitle>Delivery Details</IonCardTitle>
          </IonCardHeader>
      <input type="hidden" {...register("id")} />
        <IonList>

          {/* Name */}
          <CustomInput type="text" specForm={formErrors?.name} inputText="Name" error={errors.name} register={register("name", { required: true })} placeholder={undefined} list={undefined} itemType={undefined}></CustomInput>

          {/* Mobile */}
          <CustomInput type="tel" specForm={formErrors?.mobile} inputText="Mobile" error={errors.mobile} register={register("mobile", { required: true })} placeholder={undefined} list={undefined} itemType={undefined}></CustomInput>

          {/* Email */}
          <CustomInput type="email" specForm={formErrors?.email} inputText="Email" error={errors.email} register={register("email", { required: true })} placeholder={undefined} list={undefined} itemType={undefined}></CustomInput>

          {/* Address */}
          <CustomInput type="text" specForm={formErrors?.address} inputText="Address" error={errors.email} register={register("address", { required: true })} placeholder={undefined} list={undefined} itemType={undefined}></CustomInput>

          {/* <CustomInput type="select" specForm={formErrors?.suburb_id} inputText="Delivery Suburb" error={errors.suburb_id} register={register("suburb_id", { required: true })} placeholder="Select Suburb" list={suburbs} itemType={undefined}></CustomInput>

           */}
          <IonItem color='light'>
            <IonLabel>Delivery Suburb</IonLabel>
            <IonSelect 
              defaultValue="" 
              placeholder="Select Delivery Suburb"
              {...register("suburb_id", { required: true })} 
              interface="popover"
            >
            {suburbs && suburbs.map((suburb:Suburb, index:number) => (
              <IonSelectOption key={index} value={suburb.id}>{suburb.name}</IonSelectOption>
            ))}
            </IonSelect>

            {errors.suburb_id && fieldRequired()}
            {formErrors && formErrors.suburb_id && <FormError errors={formErrors.suburb_id}/>}
          </IonItem>

          <IonItem>
          <IonLabel onClick={() => history.push('/cert')}>Update Certification <IonIcon icon={helpCircle}></IonIcon></IonLabel>
          <IonButton onClick={() => onTakePhoto()}>
            <IonIcon icon={camera}></IonIcon>
          </IonButton>
        </IonItem>
        {client && client.profile_checked !== false &&
        <IonItem>
          <IonLabel>Payment {client?.card && client.card}</IonLabel>
          <IonButton expand="block" routerLink='/card'>
              Update Card&nbsp;<IonIcon icon={card}></IonIcon>
          </IonButton>
        </IonItem>
        }
        </IonList>

        
        </IonCard>
        <IonButton expand="block" type="submit" className="ion-margin-top ion-padding-horizontal">
              Save
            </IonButton>
        </form>
        
        <IonButton expand="block" className="ion-padding-horizontal"
        onClick={() =>
          present({
            cssClass: 'my-css',
            header: 'Alert',
            message: 'Are you sure you would like to log out?',
            buttons: [
              'Cancel',
              { text: 'Ok', handler: () => logout() },
            ],
            onDidDismiss: () => console.log('did dismiss'),
          })
        }>Logout</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default ClientPage;
