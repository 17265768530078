import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { useState, useEffect } from 'react';

import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonLoading,
  setupIonicReact
} from '@ionic/react';

import { home, list, person } from 'ionicons/icons';

import ClientPage,{Client,Suburb} from './pages/ClientPage';
import OrderPage,{Order,Product,OrderItem} from './pages/OrderPage';
import OrderSummaryPage from './pages/OrderSummaryPage';
import LoginPage from './pages/LoginPage';
import CardsPage, {Card} from './pages/CardsPage';
import ThanksPage from './pages/ThanksPage';
import CertPage from './pages/CertPage';

import HistoryPage from './pages/HistoryPage';
import AgreePage from './pages/AgreePage';
import { Storage } from '@ionic/storage';
import {UserPhoto} from './hooks/usePhotoGallery';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

import OfflinePage from './pages/OfflinePage';


const App: React.FC = () => {
  
  const [presentUpdate] = useIonAlert();


  useEffect(() => {
    window.addEventListener('hasUpdate', e => 
        presentUpdate({
          header: 'Update available',
          message: 'Please select update to get the latest version',
          buttons: ['Cancel', { text: 'Update', handler: (d) => window.location.reload()}],
          onDidDismiss: (e) => {},
        })
      );
  },[]);

  const API = process.env.REACT_APP_SERVER_URL;

  const [client, setClient] = useState<Client>();
  const [products, setProducts] = useState<Product[]>();
  const [suburbs, setSuburbs] = useState<Suburb[]>();

  const [order, setOrder] = useState<Order>();
  const [orderHistory, setOrderHistory] = useState<Order[]>();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>();
  const [token, setToken] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [authed, setAuthed] = useState(false);
  const [online, setOnline] = useState(false);
  const [agree, setAgree] = useState(false);
  const store = new Storage();

  store.create();

  useEffect(() => {
    
    (async function getStorage() {
      //await store.create();
      console.log('Load from local storage');
     
      const tmpAgree = await store.get('agree');
      setAgree(tmpAgree ? tmpAgree : false);

      const tmpId:string = await store.get('id');
      setId(tmpId ? tmpId : undefined);

      const tmpToken:string = await store.get('token');
      setToken(tmpToken ? tmpToken : undefined);

      const tmpPass:string = await store.get('pass');
      setPass(tmpPass ? tmpPass : undefined);

      const tmporderHistory = await store.get('OrderHistory');
      setOrderHistory(tmporderHistory ? tmporderHistory : []);

      //On load get server status
      checkAuthed(tmpId, tmpToken, tmpPass);
    })();

  },[]);


  useEffect(() => {
    if(authed === true &&  !products){
      //if authed, get products
      console.log("useEffect on auth");
      getProducts();
    }

  },[authed]);

  const clearAgree = async () => {
    setAgree(true);
    //await store.create();
    await store.set('agree',true);
    return true;
  };

  const saveClient = async (data:any) => {
   
    return await registerClient(data);
  };

  const saveCard = async (data:any) => {
   
    return await registerCard(data);
  };

  const saveOrder = (data:Order) => {
    if(data.order_items === undefined) return false;

    //Get total of order
    const order_total = data.order_items.reduce((total: number, order_item: OrderItem) => {
      if( !order_item.qty) return total;

      return total + (order_item.qty * order_item.product.price);
      }, 0);

    if(!order_total || order_total === 0) return false;//Test if nothing selected

    //Test if no larger bottles are selected 18 or 45
    if(data.order_items.filter( function(e) { return (
      e.qty && e.qty > 0 && (e.product.name === '45 KG' || e.product.name === '18 KG')
      ); } ).length === 0){
      return false;
    }

    const fee = Math.round( ((order_total * 0.02) + 0.30) * 100 ) / 100;
      
    console.log(order_total);
    setOrder({...data,
      total: order_total + fee,
      fee: fee
      });

    return true;
  };

  const loginClient = async (client:Client | undefined) => {
    setClient(client);
    if(client){
      setAuthed(true);
    }else{
      setAuthed(false);
    }
    return client;
  };

  const checkAuthed = async (tmpId:string, tmpToken:string, tmpPass:string) => {
    
    setLoading(true);
    try {
      const response = await fetch(`${API}/auth.json`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'Hi', id: tmpId, token: tmpToken, pass: tmpPass})
        }
      });
      
      if (!response.ok) { 
        throw Error(response.statusText);
      }
      var data = await response.json();

      if(data?.products){
        setProducts(data.products);
      }

      if(data?.suburbs){
        setSuburbs(data.suburbs);
      }
      setLoading(false);
      setOnline(true);
      if(data?.client?.id){
        setAuthed(true);
        loginClient(data.client);
        return true;
      }else{
        setAuthed(false);
        loginClient(undefined);
        return false;
      }
      
      
    } catch (error) {
      console.log(error);
      //Not online
      
      setOnline(false);
      setLoading(false);
      return false;
    }
  
  };


  const getProducts = async () => {
    console.log("getProducts");
    setLoading(true);
    try {
      const response = await fetch(`${API}/products.json`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      
      if (!response.ok) { 
        throw Error(response.statusText);
      }
      var data = await response.json();
      setOnline(true);
      if(data?.products){
        setProducts(data.products);
        setLoading(false);
        return true;
      }else{
        //spoke to server but no products :(
      }
      
      
    } catch (error) {
      console.log(error);
      //Not online
      
      setOnline(false);
      setLoading(false);
      return false;
    }
  
    setLoading(false);
    return false;
  };

  
  const registerCard = async (card:Card) => {
    setLoading(true);
    try {
      let url = API + '/cards';
      
      url += '.json';

      const response = await fetch(url,{
        method: 'POST',
        body: JSON.stringify(card),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);

      //update client
      if(data?.client?.id){
        setClient(data.client);
      }

      setLoading(false);
      return data;//Data has message
    } catch (error: any) {
      console.log(error);

      setLoading(false);
      return {
        status: 500,
        message: error.message
      };
    }

  };

  const uploadPhoto = async (photo:UserPhoto) => {
    setLoading(true);
    try {
      let url = API + '/photo';
      
      url += '.json';

      const response = await fetch(url,{
        method: 'POST',
        body: JSON.stringify(photo),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        },
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);

      //update client
      if(data?.client?.id){
        setClient(data.client);
      }

      setLoading(false);
      return data;//Data has message
    } catch (error: any) {
      console.log(error);

      setLoading(false);
      return {
        status: 500,
        message: error.message
      };
    }


  };

  const registerClient = async (client:Client) => {
    if(!client) return false;
    setLoading(true);
    try {
      let url = API + '/clients/' + client.id;
      
      url += '.json';
      console.log(authed);
      console.log(url);
      console.log(client);

      const response = await fetch(url,{
        method: 'PUT',
        body: JSON.stringify(client),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);
      if(data?.client?.id){
        setAuthed(true);
        setClient(data.client);
      }
      setLoading(false);
      return data;//Data will contain any save errors

    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    return false;
  };


  const submitOrder = async (order:Order) => {
    setLoading(true);
    try {
      let url = API + '/orders';
      
      url += '.json';
      console.log(authed);
      console.log(url);
      console.log(order);

      const response = await fetch(url,{
        method: 'POST', 
        body: JSON.stringify(order),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);
      //Update local order
      if(data?.order?.id){
        setAuthed(true);
        //TODO: Have payment status
        const tmporderHistory = orderHistory?.concat({...order, 
          id: data.order.id,
          total: data.order.total_price
        });
        setOrderHistory(tmporderHistory);
        await store.set('OrderHistory',tmporderHistory);
      }
      setLoading(false);
      return data;//Data will contain any save errors

    } catch (error) {
      console.log(error);
      
    }
    setLoading(false);
    return false;
  };

  const onLogout = async () => {
    console.log("onLogout");
    try {
      const response = await fetch(`${API}/auth/0.json`,{
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        
        throw Error(response.statusText);
      }
      var data = await response.json();
      if(data?.status == 200){
        setAuthed(false);
        loginClient(undefined);
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }

    return false;
  };


  const sendEmail = async (email: string) => {
    try {
      const response = await fetch(`${API}/auth.json`,{
        method: 'POST',
        body: JSON.stringify({email: email}),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      var data = await response.json();
      if(data?.status == 200 && data.id && data.token){
        setId(data.id);
        await store.set('id',data.id);
        setToken(data.token);
        await store.set('token',data.token);

        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }

    return false;
  };

  const sendCode = async (code: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/auth.json`,{
        method: 'POST',
        body: JSON.stringify(
          {
            code: code,
            id: id,
            token: token
          }
          ),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);
      if(data?.client?.id){
        setId(data.id);
        await store.set('id',data.id);
        setToken(data.token);
        await store.set('token',data.token);
        setPass(data.pass);
        await store.set('pass',data.pass);

        loginClient(data.client);
        setLoading(false);
        return true;
      }

    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    return false;
  };


  const [isLoading, loadingComplete] = useIonLoading();
  

  useEffect(() => {
    if(loading){
      isLoading();
    }else{
      loadingComplete();
    }

  },[loading]);

  return (
    <IonApp>

      <IonReactRouter>
      {/* {!online ? <Redirect to="/offline"/>: <></>} */}
      
      
      
        {authed && products ? 
          <IonTabs>
            <IonRouterOutlet>
    
            <Route exact path="/offline" render={(props) => <OfflinePage {...props} />}/>
            <Route exact path="/home" render={(props) => 
              { 
                //If client is new 
                if(client !== undefined && client.profile_checked === false ){
                    return (
                      <ClientPage {...props} 
                          client={client} 
                          uploadPhoto={uploadPhoto}
                          onLogout={onLogout}
                          suburbs={suburbs}
                          saveClient={saveClient} />
                    );
                }
    
                //If client all setup
                if(client !== undefined && client.payment_added === false ){
                return (
                  <CardsPage {...props} saveCard={saveCard} />);
                }
    
                //If client all setup
                return (
                <OrderPage {...props} 
              client={client} 
              products={products}
              order={order} 
              saveOrder={saveOrder} />);
            }
              }/>
    
            <Route exact path="/offline" render={props => <OfflinePage {...props}/>}/>
            
            <Route exact path="/history" render={props => 
              <HistoryPage {...props} 
                orderHistory={orderHistory} client={client} />}/>
    
              <Route exact path="/client" render={props => 
              <ClientPage {...props} 
                client={client} 
                uploadPhoto={uploadPhoto}
                onLogout={onLogout}
                suburbs={suburbs}
                saveClient={saveClient} />}/>
    
            <Route exact path="/card" render={props => 
              <CardsPage {...props} saveCard={saveCard} />}/>
    
            <Route exact path="/cert" render={props => 
              <CertPage {...props} />}/>
    
    
            <Route exact path="/ordersummary" render={props => 
              <OrderSummaryPage {...props} 
                client={client} 
                order={order} 
                submitOrder={submitOrder} />}/>
    
            <Route exact path="/thanks" render={props => 
              <ThanksPage {...props} 
              client={client}
                order={order}  />}/>
    
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
    
            </IonRouterOutlet>
              
            
            {client !== undefined && client.profile_checked && client.payment_added ?
            <IonTabBar slot="bottom">
              <IonTabButton tab="order" href="/home">
                <IonIcon icon={home} />
                <IonLabel>Order Gas</IonLabel>
              </IonTabButton>
              <IonTabButton tab="history" href="/history">
                <IonIcon icon={list} />
                <IonLabel>History</IonLabel>
              </IonTabButton>
              <IonTabButton tab="client" href="/client">
                <IonIcon icon={person} />
                <IonLabel>Profile</IonLabel>
              </IonTabButton>  
              </IonTabBar>
              :
              <IonTabBar slot="bottom">
              <IonTabButton tab="order" href="/home">
                <IonIcon icon={home} />
                <IonLabel>Setup</IonLabel>
              </IonTabButton>
              </IonTabBar>
            }
          </IonTabs>
      :
        <IonRouterOutlet>
          
          <Route exact path="/home" render={props => { return agree ? <LoginPage {...props} 
              sendCode={sendCode} sendEmail={sendEmail} /> :  <AgreePage {...props} clearAgree={clearAgree} />;} }/>
  
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
  
        </IonRouterOutlet>
      }
      
      </IonReactRouter>
      
    </IonApp>
  )
};

export default App;
